.grecaptcha-badge {
  visibility: hidden;
}

::-ms-reveal {
  display: none;
}

body {
  overscroll-behavior: none;
}
